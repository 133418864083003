import { SxStyleProp } from "theme-ui";

export const linkSx: SxStyleProp = {
  color: "primary.core",
  textDecoration: "none",
  ":hover": {
    textDecoration: "underline",
  },
};

export const linkSxLight: SxStyleProp = { ...linkSx, color: "primary.light" };

export const fullWidthSx: SxStyleProp = {
  width: "100%",
  justifyContent: "center",
};
