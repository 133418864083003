import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_DESCRIPTION =
  "Build better products faster with Alchemy's revolutionary new blockchain infrastructure and developer tools.";
const recaptchaSiteKey =
  process.env.GATSBY_RECAPTCHA_SITE_KEY ??
  "6LdgzVMeAAAAAI-I6dWRx6c4hm31VdeDF6Tijk74";

const siftBeaconKey =
  process.env.GATSBY_SIFT_BEACON_KEY ??
  // This is the production beacon key. These events will not be factored into consideration
  // when it comes to our staging environments as the API calls will use the stage key
  "98666f4ee8";

export const HCAPTCHA_SITE_KEY = getHcaptchaSiteKey();

const ALCHEMY_ANALYTICS_JS_URL =
  "https://static.alchemyapi.io/scripts/anayltics/alchemy-analytics.js";

export interface HtmlHeadProps {
  title: string;
  description?: string;
}

const HtmlHead = ({
  title,
  description = DEFAULT_DESCRIPTION,
}: HtmlHeadProps): ReactElement => (
  <Helmet
    htmlAttributes={{ lang: "en" }}
    title={title}
    titleTemplate="Alchemy - %s"
    meta={[
      { name: "description", content: description },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:type", content: "website" },
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site:id", content: "953748782394499072" },
      { name: "twitter:creator:id", content: "953748782394499072" },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      {
        name: "twitter:image",
        content:
          "https://d2krinhbbxr9gg.cloudfront.net/originals/00/7b/69/007b6975c38b8a672f4f3f05b02855f3.jpg",
      },
    ]}
    link={[
      {
        href: "https://fonts.googleapis.com/css?family=Rubik:300,400,500",
        rel: "stylesheet",
      },
    ]}
  >
    <style type="text/css">{".grecaptcha-badge { visibility: hidden; }"}</style>
    <script
      src={`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`}
    />
    <script>{`window.recaptchaSiteKey="${recaptchaSiteKey}"`}</script>
    <script src={ALCHEMY_ANALYTICS_JS_URL} />
    {getSiftScript()}
  </Helmet>
);

export default HtmlHead;

function getSiftScript(): ReactElement {
  return (
    <script type="text/javascript">
      {`
      var _sift_session_id = '${uuidv4()}';

      var _sift = window._sift = window._sift || [];
      _sift.push(['_setAccount', '${siftBeaconKey}']);
      _sift.push(['_setSessionId', _sift_session_id]);
      _sift.push(['_setTrackerUrl', 'abuse.alchemy.com']); //!!!
      _sift.push(['_trackPageview']);

     (function() {
       function ls() {
         var e = document.createElement('script');
         e.src = 'https://abuse.alchemy.com/s.js';
         document.body.appendChild(e);
       }
       if (window.attachEvent) {
         window.attachEvent('onload', ls);
       } else {
         window.addEventListener('load', ls, false);
       }
     })();
    `}
    </script>
  );
}

function getHcaptchaSiteKey() {
  if (process.env.REACT_APP_HCAPTCHA_SITE_KEY) {
    return process.env.REACT_APP_HCAPTCHA_SITE_KEY;
  }
  if (
    typeof window !== "undefined" &&
    !(
      window.location.hostname.includes("alchemy.com") ||
      window.location.hostname.includes("alchemyapi.io")
    )
  ) {
    return "7b0b2213-af25-40cb-ac2d-f24189427bb5";
  }
  return "9407b583-e518-424f-8e27-05a1e61f1a32";
}
