/** @jsx jsx */
import { memo, ReactElement } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

export interface SectionProps extends BoxProps {
  shaded?: boolean;
  centered?: boolean;
}

const Section = memo(function Section({
  shaded,
  centered,
  ...boxProps
}: SectionProps): ReactElement {
  return (
    <Box
      {...boxProps}
      px={8}
      py={5}
      sx={{
        backgroundColor: shaded ? "#F9F9FB" : "white",
        borderBottom: "1px solid",
        borderBottomColor: "gray.0",
        display: centered ? "flex" : undefined,
        flexDirection: centered ? "column" : undefined,
        alignItems: centered ? "center" : undefined,
        textAlign: centered ? "center" : undefined,

        "&:last-child": {
          borderBottomStyle: "none",

          "&:not(:only-child)": {
            pb: 8,
          },
        },
      }}
    />
  );
});
export default Section;
