/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Children, memo, ReactElement, ReactNode } from "react";
import { Box, Flex, jsx } from "theme-ui";

import { CONFIG } from "../config/config";
import logoWhite from "../images/logo-white.svg";
import HtmlHead, { HtmlHeadProps } from "./HtmlHead";
import Section from "./Section";
import { linkSx } from "./styles";

export interface MasterLayoutProps extends HtmlHeadProps {
  children: ReactNode;
}

const MasterLayout = memo(function MasterLayout({
  children,
  ...headProps
}: MasterLayoutProps): ReactElement {
  const hasActualChildren = Children.toArray(children).some((child) => child);
  const cookiesEnabled =
    typeof window === "undefined" || window.navigator.cookieEnabled;

  return (
    <Flex
      px={2}
      sx={{
        flexDirection: "column",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        minHeight: "100%",
        background:
          "linear-gradient(252.98deg, #05D5FF 4.93%, #5533FF 103.69%)",
      }}
    >
      <HtmlHead {...headProps} />
      <Box sx={{ flex: "1 0 auto" }}>
        <Flex mt={[3, 6]} sx={{ justifyContent: "center" }}>
          <Box sx={{ ":hover": { opacity: 0.75 } }}>
            <a href="https://www.alchemy.com">
              <img src={logoWhite} />
            </a>
          </Box>
        </Flex>
        {(hasActualChildren || !cookiesEnabled) && (
          <Box
            sx={{
              width: "auto",
              maxWidth: "510px",
              mx: "auto",
              my: [2, 7],
              border: "1px solid",
              borderColor: "gray.0",
              borderRadius: "8px",
              backgroundColor: "white",
              filter: "drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.25))",
              overflow: "hidden",
            }}
          >
            {cookiesEnabled ? (
              <GoogleOAuthProvider clientId={CONFIG.GOOGLE_CLIENT_ID}>
                {children}
              </GoogleOAuthProvider>
            ) : (
              <Section centered={true}>
                Cookies must be enabled to log in to Alchemy.
              </Section>
            )}
          </Box>
        )}
      </Box>
      <Box mb={9} sx={{ flexShrink: 0, textAlign: "center", color: "white" }}>
        <DUIText size="sm">
          © {new Date().getFullYear()} Alchemy Insights, Inc. •{" "}
          <a
            sx={{ ...linkSx, color: "white" }}
            href="https://alchemyapi.io/policies/terms"
          >
            Terms of Service
          </a>
        </DUIText>
      </Box>
    </Flex>
  );
});
export default MasterLayout;
